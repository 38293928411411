const theme = {
    fontFamily: "Quicksand",
    textColor: "#092733",
    lightTextColor: "#94ABB5",
    whiteTextColor: "#DEE5FF",

    infoBubbleColor: "#093E7B",

    dateNavHeight: "85px",
    headerHeight: "120px",
    headerHeightMedium: "150px",

    darkColor: "#06161E",
    darkGradient:
        "radial-gradient(circle at top center, #0C3748 -15%, #06161E 100%)",

    breakpoints: {
        medium: "1000px",
        large: "1300px",
    },
};

export default theme;
